@import "../../scss/variables";

.container {
    background-color: $red;
}

.ctaContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2px 12px;
}

.buttonContainer {
    margin: 0px 12px;

    .button {
        padding: 15px 13px;
        line-height: 9px;
        border-bottom: none;
    }
}

@media screen and (max-width: $mobile) {
    .ctaContainer {
        align-items: center;
        flex-direction: column;
        padding: 16px 12px;
    }
    
    .buttonContainer{
        .button{
            min-width: 280px;
            text-align: center;
        }
    }
}

.editingText * {
    color: $white;
}